/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
import Axios from "axios";
import Env from "Consts/env";
import { serialize } from "cookie";
// import { serialize } from "cookie";
import ClientCookie from "js-cookie";
import cacheData from "memory-cache";
import * as metaPixel from "./fb-pixel";
import * as tiktokPixel from "./tiktok-pixel";
import * as gtag from "./gtag";
import { handle498OnClient } from "./is498";

Axios.defaults.headers = {
	key: process.env.NEXT_PUBLIC_KEY_API_CLIENT,
};

export { Axios };

export const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === "production";

export const isCanary = process.env.NEXT_PUBLIC_NODE_ENV === "staging";

export const isDevelopment = process.env.NEXT_PUBLIC_NODE_ENV === "development";

export const isLocal = process.env.NEXT_PUBLIC_NODE_ENV === "local";

export const parseCookie = (str) => str
	.split(";")
	.map((v) => v.split("="))
	.reduce((acc, v) => {

		acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
		return acc;

	}, {});

export async function copyTextToClipboard(text) {

	try {

		// fitur navigator.clipboard.writeText hanya aktif di deployment (di https)
		const copiedText = await navigator?.clipboard?.writeText(text);
		return copiedText;

	} catch (err) {

		throw new Error(err);

	}

}

export const imageSrc = ({ image }) => {

	const img = image ? image.split("://") : [];

	if (image) {

		if (img.length > 1) {

			return `//${img[1]}`;

		}

		return image;

	}

	return "//static.femaledaily.com/dyn/400/images/user-pics/placeholder_user.png";

};

export const loadImage = (img) => (img === "" || img === "no_selection" ? "/images/placeholder_image_not_found.png" : img);

export const shimmerImgLoader = (w, h) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#f2f2f2" offset="20%" />
      <stop stop-color="#d8d8d8" offset="50%" />
      <stop stop-color="#f2f2f2" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#d8d8d8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

export const toBase64 = (str) => (typeof window === "undefined"
	? Buffer.from(str).toString("base64")
	: window.btoa(str));

export const Rupiah = (props) => {

	const reverse = props && props.toString().split("").reverse().join("");
	const ribuan = reverse && reverse.match(/\d{1,3}/g);
	const final = ribuan && ribuan.join(".").split("").reverse().join("");

	return `Rp ${final}`;

};

export const IdrConvert = (props) => {

	if (props && props?.toString() && props?.toString().includes(".")) {

		const rp = Number(props?.toString()?.replace(/[^0-9.-]+/g, ""));
		return Intl.NumberFormat("id-ID", {
			style: "currency",
			currency: "IDR",
		}).format(rp);

	}

	return Rupiah(props);

};

export const dateConvert = (e) => new Date(e).toDateString();

export const Alphabet = () => {

	let tmp = [];

	for (let i = 0; i < 26; i += 1) {

		tmp = tmp.concat((i + 10).toString(36).toUpperCase());

	}

	return tmp;

};

export async function adsDetik(site, params) {

	try {

		const urls = "https://detikads.femaledaily.com/static/ads/femaledaily/staging_femaledaily/";
		const res = await fetch(`${urls + site}/${params}.txt`);

		return await res.text();

	} catch (error) {

		process.exit(1);

	}

	return null;

}

export function ScrollToUp() {

	window.scrollTo({
		top: 0,
		behavior: "smooth",
	});

}

export const stringUrl = (params) => {

	const tmp = Array.isArray(params)
		? params[params.length - 1].replace(/-/g, " ")
		: params.replace(/-/g, " ");

	return tmp;

};

export const slugUrl = (params) => {

	const tmp = Array.isArray(params) ? params[params.length - 1] : params;

	return tmp;

};

export const categoryName = (slug, categories) => {

	const slugs = Array.isArray(slug) ? slug[slug.length - 1] : slug;

	const tmp = categories.filter((item) => item.slug.replace(/_/g, "-") === slugs)[0].name;

	return tmp;

};

export async function fetcher(...args) {

	const res = await fetch(...args);

	return res.json();

}

export const setGTM = () => ({
	__html: `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
		function gtag(){dataLayer.push(arguments);}
		`,
});

export const setGTMDev = () => ({
	__html: `
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID_DEV}');
		function gtag(){dataLayer.push(arguments);}
		`,
});

export const comSocre = () => ({
	__html: `
			var _comscore = _comscore || [];
			_comscore.push({ c1: "2", c2: "8443234" });
			(function() {
			var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
			s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
			el.parentNode.insertBefore(s, el);
			})();
		`,
});

export const setGoogleAnalytics = () => {

	const GaGeneral = process.env.NEXT_PUBLIC_GA_GENERAL_ID;

	return {
		__html: `
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
		})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

		ga('create', '${GaGeneral}', 'auto', 'fdn');
		ga('fdn.send', 'pageview');
		`,
	};

};

export const setMetaPixel = () => {

	const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

	return {
		__html: `
		!function(f,b,e,v,n,t,s)
		{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
		n.queue=[];t=b.createElement(e);t.async=!0;
		t.src=v;s=b.getElementsByTagName(e)[0];
		s.parentNode.insertBefore(t,s)}(window, document,'script',
		'https://connect.facebook.net/en_US/fbevents.js');
		fbq('init', '${FB_PIXEL_ID}');
		fbq('track', 'PageView');`,
	};

};

export const setMetaPixelImg = () => {

	const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

	return {
		__html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1" />`,
	};

};

export const setTiktokPixel = () => {

	const TIKTOK_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;

	return {
		__html: `!function(e,t,n){e.TiktokAnalyticsObject=n;var o=e[n]=e[n]||[];o.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],o.setAndDefer=function(e,t){e[t]=function(){e.push([t].concat(Array.prototype.slice.call(arguments,0)))}};for(var a=0;a<o.methods.length;a++)o.setAndDefer(o,o.methods[a]);o.instance=function(e){for(var t=o._i[e]||[],n=0;n<o.methods.length;n++)o.setAndDefer(t,o.methods[n]);return t},o.load=function(e,t){var a="https://analytics.tiktok.com/i18n/pixel/events.js";o._i=o._i||{},o._i[e]=[],o._i[e]._u=a,o._t=o._t||{},o._t[e]=+new Date,o._o=o._o||{},o._o[e]=t||{};var i=document.createElement("script");i.type="text/javascript",i.async=!0,i.src=a+"?sdkid="+e+"&lib="+n;var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(i,s)},o.load("${TIKTOK_PIXEL_ID}"),o.page()}(window,document,"ttq");`,
	};

};

export const setGoogleTagManagerNoscript = () => {

	const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const setGoogleTagManagerNoscriptDev = () => {

	const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID_DEV;

	return {
		__html: `
		<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
		`,
	};

};

export const getProductRecommendations = async (productId, withDispatch = false) => {

	try {

		const result = await Axios({
			method: "GET",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product/${productId}/recommendation`,
		});
		if (withDispatch) {

			withDispatch(handle498OnClient(result.data));

		}
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const createCart = async (params, withDispatch = false) => {

	try {

		const result = await Axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/items`,
			data: params,
		});
		if (withDispatch) {

			withDispatch(handle498OnClient(result?.data));

		}
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

/**
 *
 * @param {{ sku: string, qty: string, product_option?: any}} payload
 * @param {string} brandName
 * @param {function} withDispatch
 * @returns
 */
export const createCartWithEventTrack = async (payload, brandName, withDispatch = null) => {

	try {

		const result = await Axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/items`,
			data: payload,
		});

		const dataValid = result?.status >= 200 && result?.status < 300 && result?.data?.data?.item_id;
		if (dataValid && isProduction) {

			tiktokPixel.track("AddToCart", {
				content_name: result.data.name,
				content_type: "product",
				brand: brandName,
				sku: result.data.sku,
				content_ids: [result.data.item_id],
				value: result.data.price,
				currency: "IDR",
				qty: result.data.qty,
			});

			metaPixel.standardEvent("AddToCart", {
				content_name: result.data.name,
				content_type: "product",
				brand: brandName,
				sku: result.data.sku,
				content_ids: [result.data.item_id],
				value: result.data.price,
				currency: "IDR",
				qty: result.data.qty,
			});

			gtag.event({
				eventName: "add_to_cart",
				parameter: {
					id: result.data.item_id,
					name: result.data.name,
					brand: brandName,
					sku: result.data.sku,
					value: result.data.price,
					currency: "IDR",
					quantity: result.data.qty,
					event_category: "add_to_cart",
					event_label: "add_to_cart",
				},
			});

		}

		if (withDispatch) withDispatch(handle498OnClient(result?.data));

		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const updateCart = async (params, withDispatch) => {

	try {

		const result = await Axios({
			method: "PUT",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/items/${params.id}`,
			data: params,
		});
		if (withDispatch) {

			withDispatch(handle498OnClient(result.data));

		}
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const getCartTotals = async (params) => {

	try {

		const result = await Axios({
			method: "GET",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/total`,
			data: params,
		});
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const deleteCart = async (params, withDispatch) => {

	try {

		const result = await Axios({
			method: "DELETE",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/items/${params.id}`,
			data: params,
		});

		if (withDispatch) {

			withDispatch(handle498OnClient(result.data));

		}
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const profileMagento = async (params) => {

	try {

		const result = await Axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/profileMe`,
			data: params,
		});
		return result.data.data;

	} catch (error) {

		throw new Error(error);

	}

};

// eslint-disable-next-line consistent-return
export const addWishlist = async (payload, withDispatch) => {

	const authSso = ClientCookie.get("token");
	const userid = ClientCookie.get("user_id") || "";
	const pageReferer = window.location.href;
	if (!authSso) {

		window.location.href = `${process.env.NEXT_PUBLIC_SSO_DEV_DOMAIN || process.env.NEXT_PUBLIC_SSO_DOMAIN}/?url=${pageReferer}`;

	} else {

		try {

			const result = await Axios({
				method: "POST",
				url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-user/product/${userid}`,
				data: payload,
			});

			withDispatch(handle498OnClient(result.data));
			return result;

		} catch (err) {

			throw new Error("Error! ==> ", err);

		}

	}

};

export const getShipping = async (payload) => {

	try {

		const result = await Axios({
			method: "GET",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-shipping/${payload.address_id}`,
		});

		return result;

	} catch (err) {

		throw new Error("Error! ==> ", err);

	}

};

export const createOrder = async (payload) => {

	try {

		const res = await Axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-order`,
			data: payload,
		});

		return res;

	} catch (err) {

		throw new Error("Error! ", err);

	}

};

export const HandleMergeCart = async () => {

	const Cookies = ClientCookie;
	const domain = (new URL(`https://${process.env.NEXT_PUBLIC_HOST_ROOT_DOMAIN}`)).hostname.replace("www.", "");

	try {

		const mergeResult = await Axios({
			method: "POST",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/merge`,
		});

		if (mergeResult?.data?.meta?.code === 200) {

			Cookies.remove("quoteId", { path: "", domain });
			Cookies.set("user_id", mergeResult.data.data.user_id, { path: "", domain });

		}

	} catch (e) {

		console.log("error", e);

	}

};

export const HandleCreateQuote = async (params, withDispatch = false) => {

	let QuoteIdResult = null;
	let {
		Cookies = false,
	} = params;

	if (!Cookies) {

		Cookies = ClientCookie;

	}

	const quoteId = Cookies.get("quoteId");
	const domain = (new URL(`https://${process.env.NEXT_PUBLIC_HOST_ROOT_DOMAIN}`)).hostname.replace("www.", "");

	if (!quoteId) {

		try {

			return await Axios({
				method: "POST",
				url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/guest/quote_id`,
			}).then(async (res) => {

				if (withDispatch) {

					withDispatch(handle498OnClient(res.data));

				}

				if (res.data.meta.code >= 200 && res.data.meta.code < 300 && res.data) {

					await Cookies.set("quoteId", res.data.data, { httpOnly: false, domain });

				}
				return res;

			});

		} catch (e) {

			QuoteIdResult = `${e}`;

			Cookies.remove("quoteId", { path: "", domain });

		}

	}

	// } else {

	// 	// Cookies.set("quoteId", null, { httpOnly: false, domain });

	// }

	return QuoteIdResult;

};

// eslint-disable-next-line consistent-return
export const continuePayment = async (incId, token, device = null) => {

	try {

		const res = await Axios({
			method: "GET",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-order/${incId}/payment`,
			headers: {
				...Env.HEADERS,
				token: token || "",
				device: device || "3",
			},
		});
		return res;

	} catch (err) {

		throw new Error("Error! ", err);

	}

};

export const fetchWithCache = async (url, time, options) => {

	const value = cacheData.get(url);
	// clear cache
	// cacheData.clear()
	if (value) {

		return value;

	}
	const hours = typeof time !== "undefined" ? time : 1;
	options = {
		method: "GET",
		...options,
		headers: {
			...Env.HEADERS,
		},
	};
	const res = await fetch(url, options);
	let data = null;
	if (res && typeof res !== "undefined" && typeof res.status !== "undefined" && res.status === 200) {

		cacheData.put(url, data, hours * 1000 * 60 * 60);
		data = await res.json();

	}
	return data;

};

export const titleCase = (str) => { // E.g result : Padel Ganteng

	const splitStr = str.toLowerCase().split(" ");
	for (let i = 0; i < splitStr.length; i++) {

		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

	}
	// Directly return the joined string
	return splitStr.join(" ");

};

export const capitalizeFirstLetter = (param) => param[0].toUpperCase() + param.slice(1).toLowerCase(); // E.g result : Padel ganteng

export const homepageDetail = async (params) => {

	try {

		const baseUrl = (params.proxy ? `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api` : process.env.NEXT_PUBLIC_MAGENTO_BASE);
		const result = await Axios({
			method: "GET",
			url: `${baseUrl}${params.endpoint}`,
			data: params,
			headers: {
				...Env.HEADERS,
			},
		});
		return result;

	} catch (error) {

		throw new Error(error);

	}

};

export const toPositiveNum = (param) => {

	if (param < 0) {

		return Math.abs(param);

	}
	return param;

};

export const getOrderDetailById = async (param) => {

	try {

		const res = await Axios({
			method: "GET",
			url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-order/${param.id}`,
			headers: { ...Env.HEADERS },
		});
		// handle498OnClient(res)
		return res;

	} catch (error) {

		throw new Error(error);

	}

};

export const openZendeskWidget = () => {

	// eslint-disable-next-line no-undef
	zE("webWidget", "show");
	// eslint-disable-next-line no-undef
	zE("webWidget", "open");
	document.querySelector(".zendeskChatWidget").style.opacity = 0;

};

export const closeZendeskWidget = () => {

	document.querySelector(".zendeskChatWidget").style.visibility = "hidden";

};

// response template dari nestjs
export const resTemplate = ({
	data = {}, res = {}, onSuccess = null, onError = null,
}) => {

	try {

		// res.setHeader('set-cookie', ['nganu=1', 'joss=2'])
		if (data.meta.code >= 200 && data.meta.code < 300) {

			return res.json(typeof onSuccess === "function" ? onSuccess() : onSuccess || data);

		}
		if (data.meta.code === 498) {

			res.setHeader("Set-Cookie", [
				serialize("token", "", {
					maxAge: -1,
					path: "/",
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "com" : "net"}`,
				}),
				serialize("usrname", "", {
					maxAge: -1,
					path: "/",
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "com" : "net"}`,
				}),
				serialize("quoteId", "", {
					maxAge: -1,
					path: "/",
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "com" : "net"}`,
				}),
				serialize("user_id", "", {
					maxAge: -1,
					path: "/",
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "com" : "net"}`,
				}),
				serialize("tokenMagento", "", {
					maxAge: -1,
					path: "/",
					domain: `.femaledaily.${process.env.NEXT_PUBLIC_NODE_ENV === "production" ? "com" : "net"}`,
				}),
			]);
			return res.json(typeof onSuccess === "function" ? onSuccess() : onSuccess || data);

		}

	} catch (e) {

		return res.json({
			meta: {
				code: 500,
				msg: "Something went wrong",
			},
			data: [],
		});

	}
	return res.json(typeof onError === "function" ? onError() : onError || data);

};

export const kPriceFormatter = (num) => (Math.abs(num) > 999 ? `${Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))}k` : Math.sign(num) * Math.abs(num));
export const isValidHttpsImage = (string) => {

	let url;
	try {

		url = new URL(string);

	} catch (_) {

		return "https://via.placeholder.com/350x200?text=Placeholder";

	}

	url = url.href.replace(/^http:\/\//i, "https://");
	return url;

};

const mapPromo = (promo, multiCoupons, selectedCombinationRules) => promo?.map((promoitem) => {

	// update selected promo
	promoitem.selected = false;
	if (multiCoupons?.includes(promoitem.id)) {

		promoitem.selected = true;

	}

	// update availability promo
	promoitem.isAvailable = promoitem.is_applicable;
	// check and update disabled condition after clicked promo
	const maxApplied = multiCoupons.length === 2;
	const isCombinable = multiCoupons.length > 0
		? promoitem.combinable_rule_ids.filter(
			(rule) => multiCoupons.includes(rule),
		).length > 0
		: true;
	const isCombinableSelected = selectedCombinationRules?.length > 0 ? selectedCombinationRules.includes(`${promoitem.rule_id}`) : true;
	const checkIsAvailable = ((maxApplied || !isCombinable || !isCombinableSelected) && !promoitem.selected);
	if (checkIsAvailable) {

		promoitem.isAvailable = false;

	}
	return promoitem;

});

export const updatePromo = (promo, setPromo, multiCoupons) => {

	if (promo !== undefined) {

		let selectedCombinationRules = null;

		if (multiCoupons?.length > 0) {

			selectedCombinationRules = promo?.filter((item) => multiCoupons.includes(item?.rule_id)).map((item) => item.combinable_rule_ids).flat(1);

		}

		const newPromo = mapPromo(promo, multiCoupons, selectedCombinationRules);
		setPromo(newPromo);

	}

};

export const onCheckPromo = ({
	item, promo, setPromo, multiCoupons, setMultiCoupons,
}) => {

	let multiCouponsTmp = multiCoupons ? [...multiCoupons] : [];

	// update selected promo
	const newData = promo?.map((promoitem) => {

		const totalCoupon = multiCouponsTmp.length;
		const isSelectedPromo = promoitem.selected;

		if ((totalCoupon < 2 || isSelectedPromo) && (promoitem.id === item?.id)) {

			if (!isSelectedPromo) {

				multiCouponsTmp = [...multiCouponsTmp, promoitem.id];

			} else {

				const index = multiCouponsTmp.indexOf(item.id);
				multiCouponsTmp.splice(index, 1);

			}
			promoitem.selected = !isSelectedPromo;
			setMultiCoupons(multiCouponsTmp);

		}

		return promoitem;

	});

	// update avalability
	if (newData !== "undefined") {

		updatePromo(newData, setPromo, multiCouponsTmp);

	}

};

export const onApplyPromo = async ({
	item,
	fetchCart,
	setLoading,
	showDrawer,
	setPopupShow,
	setShowDrawer,
	setPromoErrMsg,
	dispatch,
	dispatchAppliedPromo = () => { },
}) => {

	setLoading(true);
	setShowDrawer((prev) => ({
		...prev,
		promotion: false,
	}));

	await Axios({
		method: "PUT",
		url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/coupons/${item}`,
	})
		.then((res) => {

			dispatch(handle498OnClient(res.data));
			if (res && res.data && res.data.meta.code === 200) {

				if (res.data.data.has_errors) {

					showDrawer.promotion
						&& setPopupShow({
							color: "#FF4D4F!important",
							show: true,
							message: res.data.data.message,
							withOk: false,
						});
					!showDrawer.promotion
						&& setPromoErrMsg(
							res.data.data.message,
						);

				} else {

					setPromoErrMsg("");

				}
				dispatchAppliedPromo();

			} else {

				showDrawer.promotion
					&& setPopupShow({
						color: "#FF4D4F!important",
						show: true,
						message: "This promotion is not applicable for this order.",
						withOk: false,
					});
				!showDrawer.promotion
					&& setPromoErrMsg(
						"Seems like this code is not applicable or invalid. Please try another code.",
					);

			}

		})
		.catch(() => false)
		.finally(() => setLoading(false));
	fetchCart();

};

export const deleteOnePromo = async ({
	item,
	setLoading,
	showDrawer,
	setShowDrawer,
	setPopupShow,
	setPromoErrMsg,
	dispatchAppliedPromo = () => { },
	dispatch,
	fetchCart,
}) => {

	setLoading(true);
	await Axios({
		method: "DELETE",
		url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-cart/coupons/${item}`,
	}).then((res) => {

		dispatch(handle498OnClient(res.data));
		if (res && res.data && res.data.meta.code === 200) {

			setPromoErrMsg("");
			setShowDrawer((prev) => ({ ...prev, promotion: false }));
			fetchCart();

		} else {

			showDrawer.promotion && setPopupShow({
				color: "#FF4D4F!important",
				show: true,
				message: "This promotion is not applicable for this order.",
				withOk: false,
			});

		}

	}).catch(() => false).finally(() => {

		setLoading(false);
		dispatchAppliedPromo();

	});

};

export const extractDate = (date) => {

	if (date) {

		const newDate = new Date(date);
		const month = newDate.toLocaleString("en-us", { month: "long" });
		const day = newDate.getDate();
		const year = newDate.getFullYear();
		return `${day} ${month} ${year}`;

	}

	return null;

};

export const checkResponseOnIsr = async (response) => {

	try {

		const data = await response.json();
		if (data?.meta?.code === 200 || data?.meta?.code === 300) {

			return data;

		}
		return null;

	} catch {

		return null;

	}

};

export const isOutOfStock = (data) => data.is_eligible === false || data.is_in_stock === false;

export const getDetailProductReview = async (masterProductId) => {

	const detailData = await Axios({
		method: "GET",
		url: `${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/api/fds-product/${masterProductId}/product-review-detail`,
		headers: {
			...Env.HEADERS,
		},
	});

	const response = detailData?.data?.data || {};
	return response;

};
