export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

// https://www.facebook.com/business/help/402791146561655
export const standardEvent = (action, parameter) => {

	window.fbq("track", action, parameter);

};

export const customEvent = (action, parameter) => {

	window.fbq("trackCustom", action, parameter);

};
